<template>
  <information-panel>
    <template v-slot:header>
      <v-icon>mdi-information-outline</v-icon>
      <p class="text-h6 font-weight-bold ml-3 mb-0">
        About
      </p>
    </template>
    <template v-slot:content>
      <section class="d-flex flex-row flex-nowrap">
        <p>{{ about }}</p>
      </section>
    </template>
  </information-panel>
</template>

<script>
export default {
  name: 'NFTListingAbout',
  props: {
    about: {
      type: String,
      required: true,
    },
  },
};
</script>
